import React, { useEffect } from "react"
import { navigate } from "gatsby"
const PageRedirect = () => {
  useEffect(() => {
    navigate(
      "/blog/reasons-why-work-from-home-might-not-actually-work-for-everyone/"
    )
  }, [])
  return <div> </div>
}
export default PageRedirect
